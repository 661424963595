import { template as template_263ab7c2ab3845a394264fb3c099648b } from "@ember/template-compiler";
const FKLabel = template_263ab7c2ab3845a394264fb3c099648b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
