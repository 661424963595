import { template as template_f0a7aeb4f62c44a48e1faf9cdf671459 } from "@ember/template-compiler";
const SidebarSectionMessage = template_f0a7aeb4f62c44a48e1faf9cdf671459(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
