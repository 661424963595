import { template as template_dde992def3ba40a0a029fd43ffed39b0 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_dde992def3ba40a0a029fd43ffed39b0(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
