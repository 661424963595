import { template as template_806ac82e762e4cc7b04b3019aa0f8a2b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_806ac82e762e4cc7b04b3019aa0f8a2b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
